import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const thanksStyle = css`
  max-width: var(--width-max-content);
  margin-top: 56px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1px;
  padding-right: 24px;
  padding-left: 24px;

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 32px;
    padding-left: 32px;
  }

  & > .content {
    padding: 28px 32px 20px;
    border-radius: 16px;
    line-height: 1.75;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 0;
      padding: 56px 80px 40px;
    }
  }

  & > .content > .title {
    font-size: ${rem(24)};
    font-weight: bold;
    text-align: center;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 64px;
      font-size: ${rem(32)};
    }
  }

  & > .content > .text {
    margin-top: 24px;
    font-size: ${rem(16)};
    text-align: center;
  }

  & > .content > .subtitle {
    margin-top: 64px;
    font-size: ${rem(24)};
    font-weight: bold;
    text-align: center;
  }

  & > .content > .meetings-iframe-container {
    margin-top: 16px;
  }

  & > .content > .button {
    margin-top: 40px;
    ${STYLE.MIXIN.BUTTON}
  }

  .icon-finish {
    ${STYLE.MIXIN.ICON_FINISH};
  }
`

const breadcrumb = [
  {
    label: 'お問い合わせ完了',
  },
]

const seo = {
  title: 'お問い合わせ完了',
  isNoindex: true,
}

const ThanksPage = () => {
  const [isIconShow, setIsIconShow] = useState(false)
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    document.body.appendChild(script)
    setIsIconShow(true)
  }, [])
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={thanksStyle}>
        <section className="content">
          <p>{isIconShow}</p>
          <div className={isIconShow ? 'icon-finish -is-show' : 'icon-finish'}>
            <div className="body">
              <div className="check"></div>
            </div>
          </div>
          <h1 className="title">お問い合わせありがとうございます</h1>
          <p className="text">
            後ほど、担当者よりご連絡をさせていただきます。
            <br />
            また「資料ダウンロード」を希望された方は、担当者より資料をメールにてお送りします。
            <br />
            今しばらくお待ちくださいますようお願い申し上げます。
          </p>

          <h2 className="subtitle">
            担当者とのお打ち合わせをご希望の方は、
            <br />
            下記よりご予約をお願いします。
          </h2>

          <div
            className="meetings-iframe-container"
            data-src="https://meetings.hubspot.com/yuki_endo/1?embed=true"
          ></div>

          <p className="button">
            <Link to="/" className="button -secondary">
              トップページへ移動する
            </Link>
          </p>
        </section>
      </main>
    </Layout>
  )
}

export default ThanksPage
